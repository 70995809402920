/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
//@import "themes";
//@import '~highlight.js/scss/github.scss';

@import "app/flag-icons.css";


html, body {
  --border-size: 2px;
  height: 100%;
  min-width: 1080px;
}

body * {
  margin: 0;
  font-family: ElectroluxSans, "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #011e41
}

body *:not(mat-icon, .fa, .material-symbols-outlined) {
  margin: 0;
  font-family: ElectroluxSans, "Helvetica Neue", sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #011e41
}


::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.7rem;
  cursor: grab;
  background-color: rgba(241, 241, 241, 0.64);
}

::-webkit-scrollbar:hover {
  width: 1.2rem;
  height: 1.2rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(241, 241, 241, 0.58);
}

/* Handle */
::-webkit-scrollbar-thumb:vertical {
  background-color: var(--primary-color);
  border-radius: 0.4rem;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  padding: 0 4px;
}
/* File: styles.css o styles.scss */
.cdk-virtual-scroll-content-wrapper {
  contain: initial !important; /* Rimuove il contain: content */
  overflow: visible !important; /* Permette di rendere visibili i dropdown */
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--primary-color);
  border-radius: 0.4rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  background-clip: padding-box;
  padding: 0 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-darker-color);
}


.elx-blue {
  color: #011e41;
}

.page-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
}

.spacer {
  flex: 1 1 auto;
}

.la-square-loader > div:after {
  background-color: white;
}

.la-square-loader > div {
  border-color: white;
}

.alert-container {
  position: absolute;
  top: 2px;
  left: 3px;
  right: 3px;
  z-index: 1030;
}

.alert-container-in-modal{
  position: relative;
  width: 100%;
  padding-bottom: 10px;
}


.form-control {
  width: initial;
}

.form-control:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 0.2rem var(--accent-lighter-color);

}

.right {
  float: right;
}

.angular-editor-textarea {
  overflow-x: auto !important;
}

.status-text-error {
  color: rgb(207, 0, 0);
}

.full-width {
  width: 100%;
}

.backdropBackground {
  backdrop-filter: blur(10px);
}

.cst-broder {
  border-style: dashed;
  border-width: 2px;
  border-radius: 10px;
  border-color: var(--primary-color);
}

.pointer {
  cursor: pointer;
  color: var(--primary-color);
}

.disabled {
  pointer-events: none;
}

:host(.disabled) {
  pointer-events: none;
}

.oblique-text {
  font-style: italic !important;
}

.page-title {
  background-color: #fafafa;
  width: 100%;
  position: sticky;
  top: -0.5rem;
  z-index: 1020;
}

/* TODO(migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-wrapper > label {
  vertical-align: baseline !important;
}

mat-sidenav-content {
  padding: 0.2rem;
  width: 100% !important;
  height: calc(100% - 64px) !important;
  overflow: hidden !important;
}

h1 {
  min-height: max-content !important;
  line-height: normal !important;
}


//.mat-tooltip {
//  background-color: var(--accent-lighter-color) !important;
//  font-size: 16px !important;
//  color: var(--primary-lighter-color) !important;
//}
//
//.cdk-overlay-pane {
//  &.mat-tooltip-panel {
//    transform: translateX(50px) !important;
//
//    .mat-tooltip {
//      background-color: var(--accent-lighter-color) !important;
//      font-size: 16px !important;
//      border: 1px solid var(--primary-lighter-color) !important;
//    }
//  }
//}

/* TODO(migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
custom-multiselect span.mat-button-wrapper {
  display: contents !important;
}

custom-multiselect button.mat-outlined-button {
  font-size: 1rem;
  padding: 0 !important;
}

.error-mat-icon {
  vertical-align: middle;
}

.mat-option-multiple .mat-option-pseudo-checkbox{
  margin-right: 8px !important;
}

.notification-container table thead {
  background-color: #B3BCC6;
  color: #011E41;
}

.notification-container table thead th {
  padding: 8px 10px
}

.notification-container table tr {
  border-bottom-width: 1px;
  border-color: #dee2e6;
}

.min-h-100 {
  min-height: 100px !important;
}
